.cursor-pointer {
    cursor: pointer;
}

.scroll-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
}
.scroll-container .indicator {
    height: 100%;
    background: #008FFB;
}

.scroll-to-top {
    position: fixed;
    bottom: 12px;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #008FFB;
    color: #fff;
    z-index: 1;
    box-shadow: 0px 3px 7px 1px #b3b3b3;
}
.scroll-to-top .icon {
    margin-top: 2px;
    margin-left: 3px;
    animation: scrollTop 0.5s alternate ease infinite;
}
@keyframes scrollTop {
    from {
        transform: translateY(2px);
    }
    to {
        transform: translateY(-1px);
    }
}